:root {
  --acn-purple: #a100ff;

  --common-gray-1: #837f89;
  --common-gray-2: #d3d3d3;
  --common-gray-3: #bfbfbf;

  --common-black-1: #212528;

  --common-pink-1: #ff48d8;

  --common-green-1: #85cf9c;

  --common-orange-1: #ff8134;

  --common-yellow: #ffcc06;
}
